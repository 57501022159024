
import { Spot } from '../models/Spot';
import { Injectable } from '@angular/core';
import spotsData from './spots-data-example';
import { SpotService } from '../services/spot.service'
import { DatabaseService } from '../services/database/database.service';
import { DBSQLiteValues, SQLiteDBConnection } from '@capacitor-community/sqlite';

@Injectable()
export class SpotRepository {
  constructor(private _databaseService: DatabaseService, private spotService: SpotService) {
  }

  async getSpots(): Promise<Spot[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      var spots: DBSQLiteValues = await db.query("select * from spots");
      return spots.values as Spot[];
    });
  }

  async createSpot(spot: Spot) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      if (!spot.image_url) {
        spot.image_url = 'https://via.placeholder.com/150';
      }
      let sqlcmd: string = "insert into spots (id,block_ids,room_ids,image_url,bio,active,radius,latitude,longitude,phone_number,spot_name,owner_name,default_location,created_by,created_at,updated_at) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)";
      let values: Array<any> = [
        spot.id,
        spot.block_ids,
        spot.room_ids,
        spot.image_url,
        spot.bio,
        spot.active,
        spot.radius,
        spot.latitude,
        spot.longitude,
        spot.phone_number,
        spot.spot_name,
        spot.owner_name,
        spot.default_location,
        spot.created_by,
        spot.created_at,
        spot.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.lastId > 0) {
        return ret.changes as Spot;
      }
      throw Error('create spot failed');
    });
  }

  async updateSpot(spot: Spot) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "update spots set name = ?, description = ?, price = ?, imageUrl = ?, isAvailable = ?, isPopular = ?, category = ? where id = ?";
      let values: Array<any> = [
        spot.active,
        spot.bio,
        spot.block_ids,
        spot.created_at,
        spot.created_by,
        spot.default_location,
        spot.id,
        spot.latitude,
        spot.longitude,
        spot.owner_name,
        spot.phone_number,
        spot.radius,
        spot.room_ids,
        spot.spot_name,
        spot.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.changes > 0) {
        return await this.getSpotById(spot.id);
      }
      throw Error('update spot failed');
    });
  }

  async getSpotById(id: any): Promise<Spot> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from spots where id like ? limit 1";
      let values: Array<any> = [id];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values[0] as Spot;
      }
      throw Error('get spot by id failed');
    });
  }

  async deleteSpotById(id: any): Promise<void> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      await db.query(`delete from spots where id = '${id}';`);
    });
  }

  async getSpotsByCategory(category: string): Promise<Spot[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from spots where category = ?";
      let values: Array<any> = [category];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values as Spot[];
      }
      throw Error('get spots by category failed');
    });
  }

  async storeLocalData(): Promise<any> {
    await this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      //delete all spots
      let sqlcmd: string = "DELETE FROM spots;";
      await db.execute(sqlcmd, false);
    });
    await this.spotService.getSpots()
    .then(async(res:any) => {
      let spots: Spot[] = spotsData;
      for (let  [spotIndex, spot] of res.spots.entries()) {
        spot["image_url"] =  'https://via.placeholder.com/150';
        await this.createSpot(spot);
      }
    })
    .catch((err:any) => {
      console.log(err)
    })


    
  }
}
