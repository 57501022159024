import { TabBarService } from  '../tab-bar.service';
import { Component, ElementRef, ViewChild } from '@angular/core';

const TAB_PAGES: any[] = [
    {
        title: 'discover',
        tab: 'discover',
        icon: 'location-outline',
        inSidemenu: false,
        inTabBar: true,
        showTabBar: true
    },
    {
        title: 'your spots',
        tab: 'spots',
        icon: 'checkmark-done-circle-outline',
        inSidemenu: false,
        inTabBar: true,
        showTabBar: true
    },
    {
        title: 'buds',
        tab: 'buds',
        icon: 'people-outline',
        inSidemenu: false,
        inTabBar: true,
        showTabBar: true
    },
    {
        title: 'newsfeed',
        tab: 'newsfeed',
        icon: 'albums-outline',
        inSidemenu: false,
        inTabBar: true,
        showTabBar: true
    },
    {
        title: 'bio',
        tab: 'bio',
        icon: 'person-circle-outline',
        inSidemenu: false,
        inTabBar: true,
        showTabBar: true
    }
];

export const TABS_ROOT: string = 'tabs';
export const APP_PAGES: any[] = TAB_PAGES.map((page: any) => {
    page.url = '/' + TABS_ROOT + '/' + page.tab;
    return page;
});

@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.page.html',
    styleUrls: ['tabs.page.scss']
})

export class TabsPage {
    public readonly tabBarPages: any =
        TAB_PAGES.filter((page: any) => page.inTabBar);
    
    @ViewChild('tabBar', {read: ElementRef, static: false})
    private tabBarRef: ElementRef;

    constructor(private tabBarService: TabBarService) {}

    public ngAfterViewInit(): void {
        const pagesShowingTabBar: Set<string> = new Set<string>(
            TAB_PAGES.filter((page: any) => page.showTabBar)
                .map((page: any) => page.tab));
        this.tabBarService.init(this.tabBarRef, pagesShowingTabBar);
    }

    handleChange(event) {
        const query = event.target.value.toLowerCase();
        // this.results = this.data.filter(d => d.toLowerCase().indexOf(query) > -1);
      }
}
