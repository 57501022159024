import { Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DatabaseService } from './database.service';
import { environment } from 'src/environments/environment';

export const createSchemaActivities: string = `
CREATE TABLE IF NOT EXISTS activities (
  id TEXT PRIMARY KEY,
  bio TEXT DEFAULT '',
  active BOOLEAN,
  radius INTEGER,
  latitude FLOAT,
  longitude FLOAT,
  phone_number TEXT DEFAULT '',
  activity_name TEXT DEFAULT '',
  owner_name TEXT DEFAULT '',
  default_location TEXT DEFAULT '',
  image_url TEXT DEFAULT '',
  created_by TEXT DEFAULT '',
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
  updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );
`;

export const createSchemaSpots: string = `
CREATE TABLE IF NOT EXISTS spots (
  id TEXT PRIMARY KEY,
  block_ids TEXT[],
  room_ids TEXT[],
  bio TEXT DEFAULT '',
  active BOOLEAN,
  radius INTEGER,
  latitude FLOAT,
  longitude FLOAT,
  phone_number TEXT DEFAULT '',
  spot_name TEXT DEFAULT '',
  owner_name TEXT DEFAULT '',
  default_location TEXT DEFAULT '',
  image_url TEXT DEFAULT '',
  created_by TEXT DEFAULT '',
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
  updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );
`;

export const createSchemaSelectedSpots: string = `
CREATE TABLE IF NOT EXISTS selectedspots (
  id TEXT PRIMARY KEY,
  block_ids TEXT[],
  room_ids TEXT[],
  bio TEXT DEFAULT '',
  active BOOLEAN,
  radius INTEGER,
  latitude FLOAT,
  longitude FLOAT,
  phone_number TEXT DEFAULT '',
  spot_name TEXT DEFAULT '',
  owner_name TEXT DEFAULT '',
  default_location TEXT DEFAULT '',
  image_url TEXT DEFAULT '',
  created_by TEXT DEFAULT '',
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
  updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
  );
`;

export const createSchemaTest: string = `
CREATE TABLE IF NOT EXISTS test (
  id INTEGER PRIMARY KEY NOT NULL,
  name TEXT NOT NULL
);
`;

@Injectable()
export class MigrationService {

  constructor
  (
    private sqliteService: SQLiteService,
    private databaseService: DatabaseService
 ) {}

  async migrate(): Promise<any> {
    await this.createTestTable();
    await this.createSpotsTable();
    await this.createActivitiesTable();
    await this.createSelectedSpotsTable();
  }

  async createSpotsTable(): Promise<any> {
    await this.databaseService.executeQuery(async (db) => {
      await db.execute(createSchemaSpots);
    });
  }

  async createSelectedSpotsTable(): Promise<any> {
    await this.databaseService.executeQuery(async (db) => {
      await db.execute(createSchemaSelectedSpots);
    });
  }

  async createActivitiesTable(): Promise<any> {
    await this.databaseService.executeQuery(async (db) => {
      await db.execute(createSchemaActivities);
    });
  }

  async createTestTable(): Promise<void> {
    const db = await this.sqliteService.createConnection(environment.databaseName, false, "no-encryption", 1);
    await db.open();
    let query = `
            CREATE TABLE IF NOT EXISTS test (
              id INTEGER PRIMARY KEY NOT NULL,
              name TEXT NOT NULL
            );
            `
    const res: any = await db.execute(query);
    await this.sqliteService.closeConnection(environment.databaseName);
  }
}
