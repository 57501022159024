
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  activities:any = []
  currentActivity:any;
  emptyActivities = false;
  

  constructor(
    private httpService: HttpClient,
    private toastController: ToastController,
    ) {}

  async presentToast(position: 'top' | 'middle' | 'bottom', message) {
    const toast = await this.toastController.create({
      duration: 1500,
      message: message,
      position: position
    });

    await toast.present();
  }


  async saveActivities(updatedActivities:any){

    
   
  }

  async initActivities(){

   
  }

  async getActivities(){
    let url = environment.api + 'activities/all'
    return this.httpService.get(url, { }).toPromise()
  }

  
}
