
import { Injectable } from '@angular/core';
import { SelectedSpot } from '../models/SelectedSpot';
import { DatabaseService } from '../services/database/database.service';
import { DBSQLiteValues, SQLiteDBConnection } from '@capacitor-community/sqlite';

@Injectable()
export class SelectedSpotRepository {
  constructor(private _databaseService: DatabaseService) {
  }

  async getSelectedSpots(): Promise<SelectedSpot[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      var selectedSpots: DBSQLiteValues = await db.query("select * from selectedspots");
      return selectedSpots.values as SelectedSpot[];
    });
  }

  async createSelectedSpot(selectedSpot: SelectedSpot) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      if (!selectedSpot.image_url) {
        selectedSpot.image_url = 'https://via.placeholder.com/150';
      }
      let sqlcmd: string = "insert into selectedspots (id,block_ids,room_ids,image_url,bio,active,radius,latitude,longitude,phone_number,spot_name,owner_name,default_location,created_by,created_at,updated_at) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)";
      let values: Array<any> = [
        selectedSpot.id,
        selectedSpot.block_ids,
        selectedSpot.room_ids,
        selectedSpot.image_url,
        selectedSpot.bio,
        selectedSpot.active,
        selectedSpot.radius,
        selectedSpot.latitude,
        selectedSpot.longitude,
        selectedSpot.phone_number,
        selectedSpot.spot_name,
        selectedSpot.owner_name,
        selectedSpot.default_location,
        selectedSpot.created_by,
        selectedSpot.created_at,
        selectedSpot.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.lastId > 0) {
        return ret.changes as SelectedSpot;
      }
      throw Error('create selectedSpot failed');
    });
  }

  async updateSelectedSpot(selectedSpot: SelectedSpot) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "update selectedspots set name = ?, description = ?, price = ?, imageUrl = ?, isAvailable = ?, isPopular = ?, category = ? where id = ?";
      let values: Array<any> = [
        selectedSpot.active,
        selectedSpot.bio,
        selectedSpot.block_ids,
        selectedSpot.created_at,
        selectedSpot.created_by,
        selectedSpot.default_location,
        selectedSpot.id,
        selectedSpot.latitude,
        selectedSpot.longitude,
        selectedSpot.owner_name,
        selectedSpot.phone_number,
        selectedSpot.radius,
        selectedSpot.room_ids,
        selectedSpot.spot_name,
        selectedSpot.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.changes > 0) {
        return await this.getSelectedSpotById(selectedSpot.id);
      }
      throw Error('update selectedSpot failed');
    });
  }

  async getSelectedSpotById(id: number): Promise<SelectedSpot> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from selectedspots where id like ? limit 1";
      let values: Array<any> = [id];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values[0] as SelectedSpot;
      }
      throw Error('get selectedSpot by id failed');
    });
  }

  async deleteSelectedSpotById(id: number): Promise<void> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      await db.query(`delete from selectedspots where id like '${id}';`);
    });
  }

  async getSelectedSpotsByCategory(category: string): Promise<SelectedSpot[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from selectedspots where category = ?";
      let values: Array<any> = [category];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values as SelectedSpot[];
      }
      throw Error('get selectedspots by category failed');
    });
  }
}
