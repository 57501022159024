import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'discover',
    loadChildren: () => import('./pages/discover/discover.module').then( m => m.DiscoverPageModule)
  },
  {
    path: 'spots',
    loadChildren: () => import('./pages/spots/spots.module').then( m => m.SpotsPageModule)
  },
  {
    path: 'spot/:id',
    loadChildren: () => import('./pages/single/spot/spot.module').then( m => m.SpotPageModule)
  },
  {
    path: 'buds',
    loadChildren: () => import('./pages/buds/buds.module').then( m => m.BudsPageModule)
  },
  {
    path: 'newsfeed',
    loadChildren: () => import('./pages/newsfeed/newsfeed.module').then( m => m.NewsfeedPageModule)
  },
  {
    path: 'bio',
    loadChildren: () => import('./pages/bio/bio.module').then( m => m.BioPageModule)
  },

  //Authentication Route
  {
    path: 'authentication',
    loadChildren: () => import('./organisms/authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
