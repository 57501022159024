
import { Activity } from '../models/Activity';
import { Injectable } from '@angular/core';
import activitiesData from './activities-data-example';
import { ActivityService } from '../services/activity.service'
import { DatabaseService } from '../services/database/database.service';
import { DBSQLiteValues, SQLiteDBConnection } from '@capacitor-community/sqlite';

@Injectable()
export class ActivityRepository {
  constructor(private _databaseService: DatabaseService, private activityService: ActivityService) {
  }

  async getActivities(): Promise<Activity[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      var activities: DBSQLiteValues = await db.query("select * from activities");
      return activities.values as Activity[];
    });
  }

  async createActivity(activity: Activity) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      if (!activity.image_url) {
        activity.image_url = 'https://via.placeholder.com/150';
      }
      let sqlcmd: string = "insert into activities (id,image_url,bio,active,radius,latitude,longitude,phone_number,activity_name,owner_name,default_location,created_by,created_at,updated_at) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)";
      let values: Array<any> = [
        activity.id,
        activity.image_url,
        activity.bio,
        activity.active,
        activity.radius,
        activity.latitude,
        activity.longitude,
        activity.phone_number,
        activity.activity_name,
        activity.owner_name,
        activity.default_location,
        activity.created_by,
        activity.created_at,
        activity.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.lastId > 0) {
        return ret.changes as Activity;
      }
      throw Error('create activity failed');
    });
  }

  async updateActivity(activity: Activity) {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "update activities set name = ?, description = ?, price = ?, imageUrl = ?, isAvailable = ?, isPopular = ?, category = ? where id = ?";
      let values: Array<any> = [
        activity.active,
        activity.bio,
        activity.created_at,
        activity.created_by,
        activity.default_location,
        activity.id,
        activity.latitude,
        activity.longitude,
        activity.owner_name,
        activity.phone_number,
        activity.radius,
        activity.activity_name,
        activity.updated_at
      ];
      let ret: any = await db.run(sqlcmd, values);
      if (ret.changes.changes > 0) {
        return await this.getActivityById(activity.id);
      }
      throw Error('update activity failed');
    });
  }

  async getActivityById(id: any): Promise<Activity> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from activities where id like ? limit 1";
      let values: Array<any> = [id];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values[0] as Activity;
      }
      throw Error('get activity by id failed');
    });
  }

  async deleteActivityById(id: any): Promise<void> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      await db.query(`delete from activities where id = '${id}';`);
    });
  }

  async getActivitiesByCategory(category: string): Promise<Activity[]> {
    return this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      let sqlcmd: string = "select * from activities where category = ?";
      let values: Array<any> = [category];
      let ret: any = await db.query(sqlcmd, values);
      if (ret.values.length > 0) {
        return ret.values as Activity[];
      }
      throw Error('get activities by category failed');
    });
  }

  async storeLocalData(): Promise<any> {
    await this._databaseService.executeQuery<any>(async (db: SQLiteDBConnection) => {
      //delete all activities
      let sqlcmd: string = "DELETE FROM activities;";
      await db.execute(sqlcmd, false);
    });
    await this.activityService.getActivities()
    .then(async(res:any) => {
      let activities: Activity[] = activitiesData;
      for (let  [activityIndex, activity] of res.activities.entries()) {
        activity["image_url"] =  'https://via.placeholder.com/150';
        await this.createActivity(activity);
      }
    })
    .catch((err:any) => {
      console.log(err)
    })


    
  }
}
