import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';

import { SpotRepository } from './repositories/spot.repository';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { DetailService } from './services/database/detail.service';
import { SQLiteService } from './services/database/sqlite.service';

import { DatabaseService } from './services/database/database.service';
import { ActivityRepository } from './repositories/activity.repository';
import { MigrationService } from './services/database/migrations.service';
import { SelectedSpotRepository } from './repositories/selectedSpot.repository';

import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { InitializeAppService } from './services/database/initialize.app.service';

export function initializeFactory(init: InitializeAppService) {
  return () => init.initializeApp();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot(),
   ],
  providers: [
    SQLiteService,
    DetailService,
    SpotRepository,
    DatabaseService,
    MigrationService,
    ActivityRepository,
    InitializeAppService,
    SelectedSpotRepository,
    {
      provide: APP_INITIALIZER,
      deps: [InitializeAppService],
      useFactory: initializeFactory,
      multi: true
    },
    ],
  bootstrap: [
    AppComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AppModule {}
